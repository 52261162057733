import VueRouter  from "vue-router";
import Vue  from "vue";
Vue.use(VueRouter)
const routes = [
    {
        path: "/",
        name: "index",
        component: () => import("../components/index.vue"),
        meta: {
            title: '首页'
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../components/login.vue"),
        meta: {
            title: '登录'
        }
    },
    {
        path: "/detail",
        name: "detail",
        component: () => import("../components/detail.vue"),
        meta: {
            title: '忘记密码'
        }
    },
   
];

const router = new VueRouter({
    routes
});

export default router;