<template>
	<div>
		<div class="top_box">
			<div class="tabbox">
				<template v-for="(item) in tablist">
					<div class="flex-c" @click="tabchange(item)">
						<div :class="item.id == ids ? 'true' : 'false'">{{ item.name }}</div>
						<div class="" :class="item.id == ids ? 'line' : 'line1'"></div>
					</div><span style="color: #999999;font-size: 0.45rem;margin-bottom: 2px;">|</span>
				</template>
			</div>
			<div class="user_box">
				<span class="logout" @click="gologin">退出登录</span>
				<span class="user_name">{{userInfo.nickname?userInfo.nickname:""}}123123</span>
				<div class="user_head">
					<img :src="userInfo.avatar?URL +userInfo.avatar:'' " class="head">
				</div>
			</div>
		</div>
		<div class="conters">
			<div class="shop_box">
				<div class="w25" v-for="(item) in list" @click="godetail(item)">
					<div class="images_box">
						<img :src="URL + item.images[0]" class="images">
					</div>
					<div class="bottom_box">
						<div style="line-height: 0.4rem;">
							<span class="jian" v-if="item.is_top == 1">(荐)</span>
							<span class="title">{{item.name}}</span>
						</div>
						<div class="subtitle">{{item.title}}</div>
					</div>
				</div>
			</div>
			<div class="pages" v-if="total > 0">
				<el-pagination background layout="prev, pager, next" :total="total" @current-change="currentChange"
					:current-page="page"></el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				tablist: [{
					name:'测试阿萨大师多撒多撒大所'
				},{
					name:'测试阿萨大师多撒多撒大所'
				},{
					name:'测试阿萨大师多撒多撒大所'
				},{
					name:'测试阿萨大师多撒多撒大所'
				},{
					name:'测试阿萨大师多撒多撒大所'
				},{
					name:'测试阿萨大师多撒多撒大所'
				},{
					name:'测试阿萨大师多撒多撒大所'
				}],
				ids: '0',
				userInfo: {},
				URL: this.$baseUrl,
				page: 1,
				total: 0,
				list: []
			}
		},
		mounted() {
			let token = localStorage.getItem("token")
			if (!token) {
				this.$message.error('请登录');
				setTimeout(() => {
					this.$router.push('/login')
				}, 500)

			} else {
				let obj = localStorage.getItem('user_info')
				this.userInfo = JSON.parse(obj)
				this.inittabList()
				this.initList()
			}
		},
		methods: {
			currentChange(e) {
				this.page = e
				this.list = []
				this.total = 0
				this.initList()
			},
			tabchange(e) {
				this.ids = e.id
				this.list = []
				this.page = 1
				this.total = 0
				this.initList()
			},
			// 获取页面数据
			initList() {
				this.request.post("/api/user/item", {
					categorys_id: this.ids,
					page: this.page,
					limit: 10
				}).then(res => {
					if (res.code == 1) {
						this.list = res.data.data
						this.total = Math.ceil(res.data.total)
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			inittabList() {
				this.request.post("/api/user/categorys", {}).then(res => {
					if (res.code == 1) {
						let arr = res.data
						let obj = {
							id: '0',
							name: '推荐'
						}
						arr.unshift(obj)
						this.tablist = arr

					} else {
						this.$message.error(res.msg);
					}
				})
			},
			gologin() {
				localStorage.setItem('token', '')
				localStorage.setItem('user_info', '')
				this.$message({
					message: '退出成功',
					type: 'success'
				});
				this.$router.push('/login')
			},
			godetail(e) {
				this.$router.push('/detail?id=' + e.id)
			},
		}
	}
</script>

<style scoped>
	* {
		margin: 0;
		padding: 0;
	}

	div {
		box-sizing: border-box;
	}

	.top_box {
		width: 100vw;
		min-width: 90vw;
		min-height: 2.1333rem;
		box-shadow: 0rem .16rem .4533rem 0rem rgba(13, 60, 76, 0.1);
		padding-top: 0.5rem;
		padding-left: 5.3333rem;
		padding-right: 5.3333rem;
		padding-bottom: 0.2rem;
		display: flex;
		justify-content: space-between;
	}

	.tabbox {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.user_box {
		width: 20vw;
		display: flex;
		justify-content: right;
		align-items: center;
	}

	.user_head {
		width: 1.3333rem;
		height: 1.3333rem;
		min-width: 1.3333rem;
		border-radius: 1.3333rem;
	}

	.user_name {
		color: #333333;
		font-size: .4267rem;
		margin-right: .8rem;
	}

	.logout {
		color: #999999;
		font-size: .32rem;
		margin-right: .8rem;
		cursor: pointer;
	}

	.head {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	.flex-c {
		display: flex;
		flex-direction: column;
		/* height: 0.8333rem; */
		justify-content: space-between;
		/* padding-left: .2333rem;
		padding-right: .2333rem; */
		padding: .2333rem;
		align-items: center;
		cursor: pointer;
	}

	.true {
		color: #708eea;
		font-size: .4333rem;
		font-weight: bold;
		white-space: nowrap;
	}

	.line {
		width: .8rem;
		height: .0533rem;
		background-color: #708eea;
	}

	.false {
		color: #333333;
		font-size: .4333rem;
		font-weight: bold;
		white-space: nowrap;
	}

	.line1 {
		width: .8rem;
		height: .0533rem;
		background-color: #FFFFFF;
	}

	.conters {
		width: 100vw;
		min-width: 100vw;
		min-height: calc(100vh - 3rem);
		padding-left: 5.3333rem;
		padding-right: 5.3333rem;
		padding-top: .5333rem;
		position: relative;
		margin: auto
	}

	.shop_box {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.w25 {
		flex: 0 0 18%;
		height: 12.2333rem;
		min-width: 7.4667rem;
		min-height: 10.9333rem;
		margin-right: .8rem;
		margin-bottom: .8rem;
		border-radius: .2667rem;
		box-shadow: 0rem .16rem .4rem .0267rem rgba(0, 0, 0, 0.11);
		position: relative;
		overflow: hidden;
		cursor: pointer;
	}

	.w25:nth-child(5n) {
		margin-right: 0rem !important;
	}

	.images_box {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	.images {
		width: 100%;
	}

	.bottom_box {
		width: 100%;
		height: 2.4333rem;
		position: absolute;
		bottom: 0;
		left: 0;
		border-top: .0267rem solid #eeeeee;
		padding-left: .2667rem;
		padding-right: .2667rem;
		padding-top: .2667rem;
		display: flex;
		flex-direction: column;
		z-index: 9999;
		background-color: #FFFFFF;
	}

	.subtitle {
		color: #999999;
		font-size: .4267rem;
		margin-top: .2667rem;
	}

	.title {
		color: #333333;
		font-size: .48rem;
	}

	.jian {
		font-size: .48rem;
		font-weight: bold;
		color: #f14800;
	}

	.pages {
		width: 100%;
		height: 1.0667rem;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		bottom: 0rem;
		left: 0rem;
		z-index: 9999;
		background-color: #FFFFFF;
	}
</style>