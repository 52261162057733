<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	import index from './components/index.vue'

	export default {
		name: 'App',
		components: {
			index
		},
	}
</script>

<style>
	* {
		margin: 0;
		padding: 0;
	}

/* 	html {
		font-size: 37.5px;
	}


	@media screen and (min-width: 768px) {
		html {
			font-size: 14px;
		}
	}

	@media screen and (min-width: 769px) and (max-width:1024px) {
		html {
			font-size: 16px;
		}
	}

	@media screen and (min-width: 1025px) and (max-width:1280px) {
		html {
			font-size: 37.5px;
		}
	} */
	@media (min-width: 376px){
	    html{
	        font-size: 8px;
	    }
	}
	@media (min-width: 415px){
	    html{
	        font-size: 8px;
	    }
	}
	@media (min-width: 640px){
	    html{
	        font-size: 12px;
	    }
	}
	@media (min-width: 720px){
	    html{
	        font-size: 14px;
	    }
	}
	@media (min-width: 750px){
	    html{
	        font-size: 15px;
	    }
	}
	@media (min-width: 800px){
	    html{
	        font-size: 15px;
	    }
	}
	@media (min-width: 1024px){
	    html{
	        font-size: 18px;
	        font-family: arial;
	    }
	}
	@media (min-width: 1100px){
	    html{
	        font-size: 20px;
	        font-family: arial;
	    }
	}
	@media (min-width: 1280px){
	    html{
	        font-size: 22px;
	        font-family: arial;
	    }
	}
	@media (min-width: 1366px){
	    html{
	        font-size: 24px;
	        font-family: arial;
	    }
	}
	@media (min-width: 1440px){
	    html{
	        font-size: 25px;
	        font-family: arial;
	    }
	}
	
	@media (min-width: 1680px){
	
	    html{
	        font-size: 28px;
	        font-family: arial;
	    }
	}
	@media (min-width: 1920px){
	    html{
	        font-size: 33px;
	        font-family: arial;
	    }
	}
</style>