import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router/router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import request from "./utils/request";
Vue.prototype.request = request
Vue.prototype.$baseUrl = 'https://alk.xdgm21.com'

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(ElementUI);


new Vue({
  render: h => h(App),
  router:router
}).$mount('#app')
